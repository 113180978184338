import { FC, ReactNode } from "react";
import { ClassNames } from "./classes";
import classNames from "classnames";

export const Tooltip: FC<{ children: ReactNode, tooltip: ReactNode }> = ({ children, tooltip }) => {
    return <div className="group/tooltip relative w-full h-full flex grow">
        {children}
        <div className={classNames(ClassNames.Card, "absolute -top-2 -translate-y-full left-1/2 -translate-x-1/2 opacity-0 pointer-events-none group-hover/tooltip:opacity-100 transition-all group-hover/tooltip:pointer-events-auto px-4 py-1")}>
            <div className={classNames(ClassNames.Text, "text-sm")}>
                {tooltip}
            </div>
        </div>
    </div>
}