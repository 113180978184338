import classNames from "classnames";
import { AnimatePresence, motion, Reorder, useDragControls } from "framer-motion";
import { flatten, forEach } from "lodash";
import { cloneElement, FC, MouseEvent, useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ClassNames } from "../../components/classes";
import { Icons } from "../../components/icons";
import { InternalPage } from "../../components/page";
import { InternalRoutes } from "../../config/routes";
import { Brand, Product, ProductSku, ProductSkuImage, useGetBrandsQuery } from "../../generated/graphql";
import { BrandProductPair, GlobalActions } from "../../store/global";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { toTitleCase } from "../../utils/functions";
import { SearchBrands } from "../brand/search-brands";
import { Loading } from "../../components/loading";

const ImageViewer: FC<{ images: string [] }> = ({ images }) => {
  const [currentImage, setCurrentImage] = useState(0);

  const handleClick = useCallback((e: MouseEvent<HTMLDivElement>) => {
    const container = e.currentTarget;
    const containerWidth = container.clientWidth;
    const containerLeft = container.getBoundingClientRect().left;
    const mouseX = e.clientX - containerLeft;

    if (mouseX < containerWidth / 2) {
      setCurrentImage((prev) => Math.max(prev - 1, 0));
    } else {
      setCurrentImage((prev) => Math.min(prev + 1, images.length - 1));
    }
  }, [images.length]);

  return (
    <div
      className="w-full h-full relative"
      onClick={handleClick}
    >
      {images.map((image, index) => {
        const isActive = index === currentImage;
        const rotation = isActive ? '0deg' : index < currentImage ? `-${(currentImage - index)*5}deg` : `${(index - currentImage)*5}deg`;
        return (
          <motion.div
            key={index}
            className="absolute top-0 left-0 w-full h-full bg-cover bg-center rounded-xl shadow-xl cursor-pointer"
            initial={{ x: '100%' }}
            animate={{
              x: isActive ? 0 : index < currentImage ? '-30%' : '30%',
              rotate: rotation,
              zIndex: isActive ? images.length : currentImage > index ? index : images.length-index,
              scale: isActive ? 1 : 0.9,
              opacity: Math.abs(index - currentImage) > 3 ? 0 : 1,
            }}
            transition={{ type: 'spring', stiffness: 300, damping: 30 }}
            style={{
              backgroundImage: `url(${image})`,
            }}
          />
        );
      })}
    </div>
  );
};

const ProductDetail: FC<{ product: Product }> = ({ product }) => {
  const uniqueSKUs = useMemo(() => {
    const skus: ProductSku[] = [];
    forEach(product.SKUs, sku => {
      const seenPaths = new Set<string>();
      const uniqueImages: ProductSkuImage[] = []
      forEach(sku.Images ?? [], (img) => {
        const url = new URL(img.Url);
        if (seenPaths.has(url.pathname)) {
          return;
        }
        seenPaths.add(url.pathname);
        uniqueImages.push({
          Color: img.Color,
          Url: `${url.protocol}//${url.hostname}/${url.pathname}`,
        });
      });
      skus.push({
        ...sku,
        Images: uniqueImages,
      })
    });
    return skus;
  }, [product.SKUs]);

    return <div className="flex flex-col gap-2 dark:bg-white/5 rounded-2xl relative overflow-hidden h-full">
      <div className={classNames(ClassNames.Text, "px-4 pt-4 pb-2 bg-black/5 dark:bg-white/5 flex flex-col gap-2 h-[100px]")}>
        {toTitleCase(product.Name)}
        <div className="grow flex justify-end items-end">
          {product.Labels.map(label => <div key={label} className="bg-teal-400/50 dark:bg-teal-500/50 px-2 py-[2px] w-fit rounded-3xl text-[10px]">{label}</div>)}
        </div>
      </div>
      <div className="flex flex-col gap-4 px-4 pb-8 items-center">
          <div className={classNames(ClassNames.Text, "text-sm self-start")}>₹ {product.SKUs?.[0]?.Price?.toLocaleString()}</div>
          <div className="h-[250px] w-[150px] my-4">
              <ImageViewer images={flatten(uniqueSKUs.map(sku => sku.Images?.map(image => image.Url) ?? []))} />
          </div>
          <div className={classNames(ClassNames.Text, "text-xs h-[150px] overflow-y-scroll")}>{product.Description} </div>
          <div className={classNames(ClassNames.Text, "text-xs h-[200px] overflow-y-scroll")}>{product.Composition}</div>
      </div>
  </div>
}

const ProductCard: FC<{ product: Product, brand: Brand, onDelete?: () => void, }> = ({ product, brand, onDelete }) => {
  const controls = useDragControls();
  const navigate = useNavigate();
  
  return (
    <Reorder.Item key={product.Id} value={product.Id} dragListener={false} dragControls={controls} className={classNames(ClassNames.Card, "relative min-w-[300px] w-[300px] group/product h-full")}>
      <div className={classNames(ClassNames.Card, "absolute -left-2 -top-2 transition-all z-10 justify-center items-center cursor-pointer px-2 py-[2px]")}
        onClick={() => navigate(InternalRoutes.Brands.Brands.path, {
          state: {
            brand,
          }
        })}>
          <div className={classNames(ClassNames.Text, "text-xs")}>{toTitleCase(brand.Name)}</div>
      </div>
      <div className="absolute right-0 -top-4 opacity-0 group-hover/product:opacity-100 z-10 flex justify-center items-center cursor-pointer gap-2">
          <div className="p-2 bg-black/20 dark:bg-white/20 shadow-lg rounded-full backdrop-blur-lg transition-all hover:scale-110" onPointerDown={(e) => controls.start(e)}>
              {cloneElement(Icons.LeftRightArrow, {
                  className: "h-4 w-4 stroke-white rotate-90",
              })}
          </div>
          <div className="p-2 bg-red-400 dark:bg-red-500 shadow-lg rounded-full backdrop-blur-lg transition-all hover:scale-110" onClick={onDelete}>
              {cloneElement(Icons.Delete, {
                  className: "h-4 w-4 stroke-white",
              })}
          </div>
      </div>
      <ProductDetail key={`product-${product.Id}`} product={product} />
    </Reorder.Item>
  )
}

export const CompareProductPage: FC = () => {
    const [shouldSearch, setShouldSearch] = useState(false);
    const { data: brands, loading } = useGetBrandsQuery();
    const comparingProducts = useAppSelector(state => state.global.comparingProducts);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handleSearchBrandClick = useCallback((brand: Brand) => {
      navigate(InternalRoutes.Brands.Brands.path, {
        state: {
          brand,
        },
      });
      setShouldSearch(false);
  }, [navigate]);

  const handleDeleteProduct = useCallback((product: Product) => {
    dispatch(GlobalActions.removeComparingProduct({ id: product.Id }));
  }, [dispatch]);
  
  const productMap = useMemo(() => {
      return comparingProducts.reduce((all, one) => {
          all[one.product.Id] = one;
          return all;
      }, {} as Record<string, BrandProductPair>)
  }, [comparingProducts]);

  const { items, setItems } = useMemo(() => {
    return {
        items: comparingProducts.map(({ product }) => product.Id),
        setItems: (items: string[]) => {
            dispatch(GlobalActions.setAllComparingProducts(items.map(item => productMap[item]),));
        }
    }
  }, [productMap, comparingProducts, dispatch]);

  if (loading) {
    <InternalPage routes={[InternalRoutes.Dashboard, {
      ...InternalRoutes.Product.Compare,
    }]}>
      <Loading />
    </InternalPage>
  }

  return <InternalPage routes={[InternalRoutes.Dashboard, {
    ...InternalRoutes.Product.Compare,
  }]}>
      <div className="flex w-full h-full">
          <div className="flex flex-col items-start">
              <div className="flex flex-col gap-8 w-full">
                  <div className={classNames(ClassNames.Title, "text-2xl")}>
                      Compare Products
                  </div>
              </div>
              { shouldSearch && <SearchBrands brands={brands?.Brand ?? []} show={shouldSearch} onClose={() => setShouldSearch(false)} onClick={handleSearchBrandClick} /> }
              <div className="flex gap-4 items-start h-[calc(100vh-100px)]">
                <AnimatePresence mode="sync">
                    <div className={classNames(ClassNames.Card, "relative min-w-[150px] group/product h-[calc(100%-30px)] justify-center items-center cursor-pointer mt-4")} onClick={() => setShouldSearch(true)}>
                      <div className={classNames(ClassNames.Text, "flex flex-col gap-2 items-center")}>
                          {cloneElement(Icons.Add, {
                              className: "w-12 h-12 group-hover/product:scale-110 transition-all",
                          })}
                          Add Products
                      </div>
                    </div>
                    <Reorder.Group axis="x" values={items} onReorder={setItems} className="flex h-full gap-4 max-w-[86vw] overflow-x-scroll py-4 pl-4"
                        variants={{
                          open: { opacity: 1, transition: { staggerChildren: 0.2, delayChildren: 0.05 }},
                          close: { opacity: 0, transition: { staggerChildren: 0.05, staggerDirection: -1 }}
                      }} animate={comparingProducts.length > 0 ? "open" : "close"}>
                        {
                            comparingProducts.map(({ product, brand }) => (
                                <ProductCard key={product.Id} product={product} brand={brand} onDelete={() => handleDeleteProduct(product)} />
                            ))
                        }
                    </Reorder.Group>
                  </AnimatePresence>
              </div>
          </div>
      </div>
  </InternalPage>
}