import Keycloak from "keycloak-js";
import { FC, useEffect, useState } from "react";
import { AppDispatch } from "../store";
import { AuthActions } from "../store/auth";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { Loading } from "./loading";
import { NANOKNOT_ACCESS_TOKEN, NANOKNOT_REFRESH_TOKEN } from "../config/constants";
import { setCookie } from "../utils/functions";
import { InternalPage } from "./page";


const keycloakInstance: Keycloak = new Keycloak({
  url: "https://auth.nanoknot.com",
  realm: "vault",
  clientId: "portal",
});

function setToken(dispatch: AppDispatch, token: string, refreshToken: string) {
  setCookie(NANOKNOT_ACCESS_TOKEN, token, 30);
  setCookie(NANOKNOT_REFRESH_TOKEN, refreshToken, 30);
  dispatch(AuthActions.setToken({
    accessToken: token,
    refreshToken: refreshToken,
  }));
}

type KeycloakProviderProps = {
  children?: React.ReactNode;
}

function refreshKeycloakToken(dispatch: AppDispatch) {
  keycloakInstance.onTokenExpired = () => {
    return keycloakInstance.updateToken().then(refreshed => {
      if (refreshed && keycloakInstance.token != null && keycloakInstance.refreshToken != null) {
        setToken(dispatch, keycloakInstance.token, keycloakInstance.refreshToken);
      }
    }).catch(() => {
      console.error('Please contact support if this issue persists');
    });
  }
}

function keepTokenAlive(dispatch: AppDispatch) {
  refreshKeycloakToken(dispatch);
  setInterval(() => {
    refreshKeycloakToken(dispatch);
  }, 1000 * 30);
}

export const KeycloakProvider: FC<KeycloakProviderProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const [success, setSuccess] = useState<boolean>(false);
  const auth = useAppSelector(state => state.auth);

  useEffect(() => {
    if (keycloakInstance.authenticated === true) {
      keepTokenAlive(dispatch);
      setSuccess(true);
      return;
    };
    if (keycloakInstance.authenticated === false) {
      refreshKeycloakToken(dispatch);
      keepTokenAlive(dispatch);
      return;
    }
    keycloakInstance.init({ onLoad: 'login-required' }).then((authenticated) => {
      if (authenticated == null || keycloakInstance.token == null || keycloakInstance.refreshToken == null) {
        return window.location.reload();
      }
      setToken(dispatch, keycloakInstance.token, keycloakInstance.refreshToken);
      keepTokenAlive(dispatch);
      dispatch(AuthActions.setStatus("success"))
      setSuccess(true);
    }).catch((e) => {
      dispatch(AuthActions.setStatus("failed"))
      console.error("failed", e);
    });
  }, [dispatch]);

  useEffect(() => {
    if (auth.status === "logging-out") {
      keycloakInstance.logout({
        redirectUri: `${window.location.protocol}//${window.location.host}`,
      }).then(() => {
        dispatch(AuthActions.setStatus("unauthenticated"));
      }).catch((e) => {
        dispatch(AuthActions.setStatus("failed"))
        console.error("failed", e);
      });
    } 
  }, [auth.status, dispatch]);

  if (auth.status === "failed") {
    return <div className="flex h-[100vh] w-[100vw] justify-center items-center">
      Unauthorized
    </div>
  }

  if (!success) {
    return <InternalPage>
      <Loading />
    </InternalPage>
  }

  return <>
    {children}
  </>;
}
