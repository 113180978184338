import classNames from "classnames";
import { cloneElement, FC, useEffect } from "react";
import { ClassNames } from "../../components/classes";
import { Icons } from "../../components/icons";
import { Page } from "../../components/page";
import { useDispatch } from "react-redux";
import { AuthActions } from "../../store/auth";
import { setCookie } from "../../utils/functions";
import { NANOKNOT_ACCESS_TOKEN, NANOKNOT_REFRESH_TOKEN } from "../../config/constants";
import { AppDispatch } from "../../store";

function removeToken(dispatch: AppDispatch) {
  setCookie(NANOKNOT_ACCESS_TOKEN, "", 30);
  setCookie(NANOKNOT_REFRESH_TOKEN, "", 30);
  dispatch(AuthActions.setToken(undefined));
}

export const LogoutPage: FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(AuthActions.setStatus("logging-out"));
    removeToken(dispatch);
  }, [dispatch]);

  return <Page className="flex justify-center items-center">
    <div className="z-10 flex justify-center items-center backdrop-blur-md h-full w-full">
      <div className={classNames(ClassNames.Text, "text-lg self-center")}>
          Logging out
      </div>
    </div>
    {cloneElement(Icons.Lock, {
        className: "absolute w-[50%] h-[50%] stroke-white brightness-[0.15]",
    })}
  </Page>
}