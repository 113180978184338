import { FC, useCallback, useState } from "react";
import { ClassNames } from "./classes";
import { AnimatePresence, motion } from "framer-motion";
import { Icons } from "./icons";
import classNames from "classnames";
import { formatToIndianNumbering } from "../utils/functions";

export const Slider: FC<{
    placeholder?: string;
    min: number;
    max: number;
    step?: number;
    onChange?: (range: [number, number]) => void;
    className?: string,
}> = ({ placeholder, min, max, step = 1, onChange, className }) => {
    const [range, setRange] = useState<[number, number]>([min, max]);
    const [open, setOpen] = useState(false);

    const handleOpen = useCallback(() => {
        setOpen(o => !o);
    }, []);

    const handleSliderChange = useCallback((e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const value = Number(e.target.value);
        setRange(prevRange => {
            const newRange = [...prevRange] as [number, number];
    
            if (index === 0) {
                newRange[0] = value;
                if (newRange[0] > newRange[1]) {
                    newRange[1] = newRange[0];
                }
            } else {
                newRange[1] = value;
                if (newRange[1] < newRange[0]) {
                    newRange[0] = newRange[1];
                }
            }
            return newRange;
        });
    }, []);

    const handleApply = useCallback(() => {
        onChange?.(range);
        setOpen(false);
    }, [onChange, range]);

    return (
        <div className="relative inline-block text-left">
            <div>
                <button
                    type="button"
                    className={classNames(ClassNames.Button, className)}
                    id="menu-button"
                    aria-expanded="true"
                    aria-haspopup="true"
                    onClick={handleOpen}
                >
                    <span>{placeholder}</span>
                    <span>({formatToIndianNumbering(range[0])} - {formatToIndianNumbering(range[1])})</span>
                    {Icons.DownChevron}
                </button>
            </div>
            <AnimatePresence mode="wait">
                {open && (
                    <>
                        <div
                            className="fixed inset-0 z-10"
                            onClick={handleApply}
                        />
                        <motion.div
                            initial={{ opacity: 0, scale: 0.95 }}
                            animate={{ opacity: 100, scale: 1 }}
                            exit={{ opacity: 0, scale: 0.95 }}
                            className="absolute right-0 z-10 mt-2 w-[300px] origin-top-right rounded-md bg-black/50 backdrop-blur-lg shadow-black shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none p-4"
                            role="menu"
                            aria-orientation="vertical"
                            aria-labelledby="menu-button"
                            tabIndex={-1}
                        >
                            <div className="py-2" role="none">
                                <div className="flex flex-col space-y-2">
                                    <span className={classNames(ClassNames.Text, "text-sm")}>Min: {formatToIndianNumbering(range[0])}</span>
                                    <input
                                        type="range"
                                        min={min}
                                        max={max}
                                        step={step}
                                        value={range[0]}
                                        onChange={(e) => handleSliderChange(e, 0)}
                                        className="w-full"
                                    />
                                    <span className={classNames(ClassNames.Text, "text-sm")}>Max: {formatToIndianNumbering(range[1])}</span>
                                    <input
                                        type="range"
                                        min={min}
                                        max={max}
                                        step={step}
                                        value={range[1]}
                                        onChange={(e) => handleSliderChange(e, 1)}
                                        className="w-full"
                                    />
                                </div>
                            </div>
                        </motion.div>
                    </>
                )}
            </AnimatePresence>
        </div>
    );
};
