import { FC } from "react"
import { Loading } from "./loading"
import classNames from "classnames"
import { ClassNames } from "./classes"
import { Icons } from "./icons"
import { motion } from "framer-motion"

export type ViewControlType = "table" | "image-sm" | "image-md" | "image-lg";

export const ViewControl: FC<{ loading: boolean, view: ViewControlType, setView: (view: ViewControlType) => void }> = ({ loading, view, setView }) => {
    return <div className="flex gap-2 items-center relative">
        {
          loading &&
          <div className="h-fit w-[50px]">
            <Loading className="h-8" />
          </div>
        }
        <div className={classNames(ClassNames.Button, "relative hover:scale-110")} onClick={() => setView("table")}>
          {Icons.Table}
          { view === "table" && <motion.div layoutId="underline" className="w-1 h-1 absolute left-[calc(50%-2px)] -bottom-2 rounded-full bg-white/60" /> }
        </div>
        <div className={classNames(ClassNames.Button, "relative hover:scale-110")} onClick={() => setView("image-sm")}>
          <div className="border border-white/50 h-4 w-2 rounded-sm" />
          { view === "image-sm" && <motion.div layoutId="underline" className="w-1 h-1 absolute left-[calc(50%-2px)] -bottom-2 rounded-full bg-white/60" /> }
        </div>
        <div className={classNames(ClassNames.Button, "relative hover:scale-110")} onClick={() => setView("image-md")}>
          <div className="border border-white/50 h-4 w-4 rounded-sm" />
          { view === "image-md" && <motion.div layoutId="underline" className="w-1 h-1 absolute left-[calc(50%-2px)] -bottom-2 rounded-full bg-white/60" /> }
        </div>
        <div className={classNames(ClassNames.Button, "relative hover:scale-110")} onClick={() => setView("image-lg")}>
          <div className="border border-white/50 h-4 w-6 rounded-sm" />
          { view === "image-lg" && <motion.div layoutId="underline" className="w-1 h-1 absolute left-[calc(50%-2px)] -bottom-2 rounded-full bg-white/60" /> }
        </div>
      </div>
}