import classNames from "classnames";
import { motion } from "framer-motion";
import { filter, lowerCase } from "lodash";
import { FC, useMemo, useState } from "react";
import { ClassNames } from "../../components/classes";
import { Icons } from "../../components/icons";
import { Input } from "../../components/input";
import { Brand } from "../../generated/graphql";
import { toTitleCase } from "../../utils/functions";

export const SearchBrands: FC<{
  brands: Brand[],
  onClick: (brand: Brand) => void,
  show: boolean,
  onClose?: () => void
}> = ({ show, onClose, brands, onClick }) => {
    const [search, setSearch] = useState("");

    const filteredBrands = useMemo(() => {
        const lowerCaseSearch = lowerCase(search);
        return filter(brands ?? [], brand => lowerCase(brand.Name).includes(lowerCaseSearch))
      }, [brands, search]);

    return <motion.ul className="flex flex-col justify-center items-center shadow-lg backdrop-blur-lg z-[1000] gap-2 fixed inset-0"
        variants={{
          open: { opacity: 1, transition: { staggerChildren: 0.07, delayChildren: 0.05 }},
          close: { opacity: 0, transition: { staggerChildren: 0.05, staggerDirection: -1 }}
        }} initial="close" animate={show ? "open" : "close"}>
          <div className="absolute inset-0 dark:bg-black/50 -z-10" />
        <motion.li className="w-[25vw]"
          variants={{
            open: { opacity: 1, transition: { y: { stiffness: 1000, velocity: -100 }} },
            close: { opacity: 0, }
          }}>
              <Input placeholder="Search by brand name..." value={search} setValue={setSearch} />
          </motion.li>
          <div className="flex flex-col gap-2 h-[30vh] overflow-y-scroll overflow-x-hidden">
            {
              filteredBrands.map(brand => (
                <motion.li className="w-[25vw]" key={brand.Id}
                  variants={{
                    open: { y: 0, opacity: 1, transition: { y: { stiffness: 1000, velocity: -100 }}},
                    close: { y: 50, opacity: 0, },
                  }}
                  whileHover={{ x: 30, scale: 1.1, }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => onClick(brand)}
                >
                  <div className={classNames(ClassNames.Button, "text-4xl")}>
                    {toTitleCase(brand.Name)}
                  </div>
                </motion.li>
              ))
            }
          </div>
        {
            onClose != null && 
            <motion.li className="w-[25vw] flex justify-center mt-8"
            variants={{
                open: { opacity: 1, transition: { y: { stiffness: 1000, velocity: -100 }} },
                close: { opacity: 0, }
            }}>
                <div className={ClassNames.Button} onClick={onClose}>{Icons.Cancel} Close</div>
            </motion.li>
        }
      </motion.ul>
}