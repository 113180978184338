import { FC } from "react";

export const Logo: FC = () => {
    return <svg viewBox="0 0 449 353" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M112.5 284.5C173.804 284.5 248.5 200.207 248.5 132C248.5 63.7928 173.804 34 112.5 34C51.1964 34 0 91.2928 0 159.5C0 227.707 51.1964 284.5 112.5 284.5ZM115.422 254C162.787 254 220.5 189.729 220.5 137.723C220.5 85.7163 162.787 63 115.422 63C68.0562 63 28.5 106.684 28.5 158.691C28.5 210.697 68.0562 254 115.422 254Z" fill="#595959"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M266.233 284.5C332.841 284.5 414 190.449 414 114.345C414 38.2419 365.108 0 298.5 0C231.892 0 144 68.9255 144 145.029C144 221.132 199.626 284.5 266.233 284.5ZM269.408 250.469C320.871 250.469 383.577 178.757 383.577 120.73C383.577 62.7034 320.871 37.3573 269.408 37.3573C217.944 37.3573 174.966 86.0989 174.966 144.126C174.966 202.153 217.944 250.469 269.408 250.469Z" fill="#424242"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M158.684 98.408C127.724 152.033 167.15 261.915 229.577 297.957C292.004 333.999 346.099 312.748 377.06 259.124C408.02 205.499 392.334 102.095 329.907 66.053C267.48 30.0107 189.644 44.7834 158.684 98.408ZM185.124 117.081C161.203 158.513 190.881 242.959 238.48 270.441C286.079 297.922 336.017 259.442 359.938 218.01C383.859 176.577 363.854 118.892 316.255 91.4108C268.656 63.9295 209.045 75.6483 185.124 117.081Z" fill="#2E2E2E"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M155.368 53C93.4479 53 18 142.085 18 214.169C18 286.254 63.4518 322.477 125.372 322.477C187.293 322.477 269 257.191 269 185.106C269 113.021 217.289 53 155.368 53ZM152.417 85.234C104.575 85.234 46.2817 153.159 46.2817 208.122C46.2817 263.084 104.575 287.092 152.417 287.092C200.259 287.092 240.213 240.924 240.213 185.962C240.213 130.999 200.259 85.234 152.417 85.234Z" fill="black"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M261 278.757C253.509 277.453 245.966 274.762 238.48 270.441C229.334 265.16 220.85 257.777 213.281 249H180.821C194.139 269.632 210.82 287.128 229.577 297.957C240.323 304.162 250.823 308.668 261 311.604V278.757Z" fill="#2E2E2E"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M199.986 197C188.997 213.545 174.539 228.346 159 238.718V270.613C186.75 254.87 212.859 227.41 229.633 197H199.986Z" fill="#595959"/>
    </svg>
}