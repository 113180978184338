import classNames from "classnames";
import { AnimatePresence } from "framer-motion";
import { FC, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PaddedPieChart } from "../../components/charts/pie-chart";
import { ClassNames } from "../../components/classes";
import { Icons } from "../../components/icons";
import { InternalPage } from "../../components/page";
import { InternalRoutes } from "../../config/routes";
import { AnalyticsType, Brand, ProductSku, useGetBrandsQuery, useGetOverallAnalyticsQuery, useGetProductsQuery, useGetVersionQuery } from "../../generated/graphql";
import { formatNumberWithK, getFirstImageUrl, getImageUrl, isNumeric, toTitleCase } from "../../utils/functions";
import { SearchBrands } from "../brand/search-brands";
import { Loading } from "../../components/loading";
import { twMerge } from "tailwind-merge";

const pageOffset = Math.round(Math.random()*20);

export const DashboardPage: FC = () => {
  const navigate = useNavigate();
  const { data: analytics, loading: analyticsLoading } = useGetOverallAnalyticsQuery();
  const [shouldSearch, setShouldSearch] = useState(false);
  const { data: brands, loading: brandsLoading } = useGetBrandsQuery();
  const { data: version } = useGetVersionQuery();

  const { data: products, loading: productsLoading } = useGetProductsQuery({
    variables: {
      pageSize: 6,
      pageOffset,
      conditions: {},
    },
  });

  const handleSearchBrandClick = useCallback((brand: Brand) => {
    navigate(InternalRoutes.Brands.Brands.path, {
      state: {
        brand,
      },
    });
    setShouldSearch(false);
  }, [navigate]);

  if (analyticsLoading || brandsLoading || productsLoading) {
    return <InternalPage>
      <Loading />
    </InternalPage>
  }

  return <InternalPage routes={[InternalRoutes.Dashboard]}>
      <div className="flex justify-center w-full h-full gap-20 pt-[10vh]">
        <div className="flex flex-col items-start h-[60vh] gap-8">
          <div className="flex flex-col gap-8 w-full">
            <div className={classNames(ClassNames.Title, "text-2xl")}>
              Quick analytics
            </div>
          </div>
          <div className="flex flex-wrap w-full items-center justify-around space-y-4">
            {
              analytics?.OverallAnalytics.map(analytic => (
                <div key={analytic.Title} className="flex flex-col gap-2 items-center h-fit w-1/3 ">
                  { analytic.Type === AnalyticsType.Highlight &&
                      <div key="highlight" className={classNames(ClassNames.Text, "text-[92px]")}>
                        {analytic.Highlight?.Value != null && isNumeric(analytic.Highlight.Value) ? formatNumberWithK(Number.parseFloat(analytic.Highlight.Value)) : analytic.Highlight?.Value}
                      </div>
                  }
                  {
                    analytic.Type === AnalyticsType.PieChart &&
                    <PaddedPieChart key="pie-chart" height={150} width={200} data={analytic.PieChart?.Labels?.map((name, i) => ({ name, value: analytic.PieChart?.Values[i] ?? 0 })) ?? []} />
                  }
                  <div className="flex flex-col items-center justify-center">
                      <div className={classNames(ClassNames.Text, "text-lg")}>{analytic.Title}</div>
                      <div className={classNames(ClassNames.Text, "text-xs")}>{analytic.SubTitle != null && `(${analytic.SubTitle})`}</div>
                  </div>
                </div>
              ))
            }
          </div>
          <div className="flex flex-col gap-8 w-full mt-8">
            <div className={classNames(ClassNames.TitledButton, "text-2xl")} onClick={() => setShouldSearch(true)}>
              Explore brands
              {Icons.RightArrow}
            </div>
            <div className="flex gap-4 w-full pl-8">
              {
                brands?.Brand.slice(0, 3).map(brand => (
                  <div key={brand.Id} className={twMerge(classNames(ClassNames.OutlinedButton, "border-none text-2xl bg-white/10 rounded-3xl px-4 py-2 hover:scale-105"))}
                    onClick={() => handleSearchBrandClick(brand)}>
                    {toTitleCase(brand.Name)}
                  </div>
                ))
              }
              <div className={classNames(ClassNames.Button, "text-2xl bg-white/10 rounded-3xl px-4 py-2 hover:scale-105 items-center")}
                onClick={() => setShouldSearch(true)}>
                  {Icons.Search} Search...
                </div>
                <AnimatePresence mode="sync">
                  {
                    shouldSearch &&
                    <SearchBrands brands={brands?.Brand ?? []} show={shouldSearch} onClose={() => setShouldSearch(false)}
                      onClick={handleSearchBrandClick} />
                  }
                </AnimatePresence>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap w-[250px] gap-2 h-[60vh] gap-y-4">
          <div className="flex flex-col gap-8 w-full cursor-pointer" onClick={() => navigate(InternalRoutes.Product.Product.path)}>
            <div className={classNames(ClassNames.TitledButton, "text-2xl")}>
              Explore Products
              {Icons.RightArrow}
            </div>
          </div>
          {
            products?.Products.map(product => (
              <>
                {getFirstImageUrl(product.SKUs as ProductSku[]) != null && <img key={product.Id} className="rounded-lg w-[120px] h-[180px] object-cover" src={getImageUrl(getFirstImageUrl(product.SKUs as ProductSku[])!)} alt="Overview collage" /> }
              </>
            ))
          }
        </div>
      </div>
      <div className={classNames(ClassNames.Text, "absolute bottom-4 right-4 text-sm opacity-25 hover:opacity-100")}>
        {version?.Version}
      </div>
  </InternalPage>
}