import classNames from "classnames";
import { isArray, max, min } from "lodash";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { PaddedPieChart } from "../../components/charts/pie-chart";
import { ClassNames } from "../../components/classes";
import { Filter, getFilterConditions, useFilter } from "../../components/filter";
import { Loading } from "../../components/loading";
import { InternalPage } from "../../components/page";
import { Table } from "../../components/table";
import { ViewControl, ViewControlType } from "../../components/view-control";
import { InternalRoutes } from "../../config/routes";
import { AnalyticsType, Brand, Conditions, Product, useGetBrandAnalyticsQuery, useGetBrandProductsLazyQuery } from "../../generated/graphql";
import { formatNumberWithK, isNumeric, toTitleCase } from "../../utils/functions";
import { ProductDetails } from "../product/product-details";
import { ProductCard } from "../product/products";

const PAGE_SIZE = 30;

const BrandAnalyticsDetails: FC<{ brand: Brand, conditions: Conditions }> = ({ brand, conditions }) => {
  const { data: analytics, loading } = useGetBrandAnalyticsQuery({
    variables: {
      brandId: brand?.Id,
      conditions,
    },
  });

  if (loading) {
    return <div className="h-[25vh] w-full">
      <Loading className="h-8" />
    </div>
  }

  return <div className="flex flex-col gap-20">
    <div className="flex flex-col w-full gap-4">
      <div className={classNames(ClassNames.Title, "text-lg")}>
        Quick analytics
      </div>
    </div>
    <div className="flex flex-wrap w-full items-center justify-around">
      {
        analytics?.BrandAnalytics.filter(analytics => analytics.Type !== AnalyticsType.QuartileChart).slice(0, 6).map(analytic => (
          <div key={analytic.Title} className="flex flex-col gap-2 items-center h-fit w-1/3 ">
            { analytic.Type === AnalyticsType.Highlight &&
                <div key="highlight" className={classNames(ClassNames.Text, "text-[92px]")}>
                  {analytic.Highlight?.Value != null && isNumeric(analytic.Highlight.Value) ? formatNumberWithK(Number.parseFloat(analytic.Highlight.Value)) : analytic.Highlight?.Value}
                </div>
            }
            {
              (analytic.Type === AnalyticsType.PieChart || analytic.Type === AnalyticsType.VerticalDistributionChart) &&
              <PaddedPieChart key="pie-chart" height={150} width={200} data={analytic.PieChart?.Labels?.map((name, i) => ({ name, value: analytic.PieChart?.Values[i] ?? 0 })) ?? []} />
            }
            <div className="flex flex-col items-center justify-center">
                <div className={classNames(ClassNames.Text, "text-lg")}>{analytic.Title}</div>
                <div className={classNames(ClassNames.Text, "text-xs")}>{analytic.SubTitle != null && `(${analytic.SubTitle})`}</div>
            </div>
          </div>
        ))
      }
    </div>
  </div>
}

const BrandProductDetails: FC<{ brand: Brand, conditions: Conditions, onClick: (product: Product) => void }> = ({ brand, conditions, onClick }) => {
  const [view, setView] = useState<ViewControlType>("image-sm");
  const [products, setProducts] = useState<Product[]>([]);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const tableRef = useRef<HTMLDivElement | null>(null);
  const [getBrandProducts, { loading }] = useGetBrandProductsLazyQuery({
    onCompleted(data) {
      setProducts(p => [...p, ...(data.BrandProducts as Product[] ?? [])]);
    },
  });

  useEffect(() => {
    getBrandProducts({
      variables: {
        brandId: brand.Id,
        pageSize: PAGE_SIZE,
        pageOffset: 0,
        conditions,
      },
      onCompleted(data) {
        setProducts(data.BrandProducts as Product[]);
      },
    });
  }, [brand.Id, conditions, getBrandProducts, setProducts]);

  const rows = useMemo(() => {
    if (products.length === 0) {
      return [];
    }
    return products.map(product => {
      const skusPrice = product.SKUs.map(sku => sku.Price);
      const minPrice = min(skusPrice);
      const maxPrice = max(skusPrice);
      const colors = product.SKUs.reduce((all: Set<string>, one) => {
        (one.Images?.map(image => image.Color) ?? []).forEach(color => all.add(color));
        return all;
      }, new Set<string>());
      return [product.Name, maxPrice == null ? "Unknown" : minPrice === maxPrice ? `${maxPrice}` : `${minPrice} - ${maxPrice}`, product.Description, product.Labels, [...colors.values()]];
    });
  }, [products]);

  const handleScroll = useCallback(() => {
    const container = containerRef.current || tableRef.current;
    if (container != null) {
      const { scrollTop, scrollHeight, clientHeight } = container;
      if (scrollTop + clientHeight >= scrollHeight - 50) {
        getBrandProducts({
          variables: {
            brandId: brand.Id,
            pageSize: PAGE_SIZE,
            pageOffset: products.length,
            conditions,
          },
        });
      }
    }
  }, [brand.Id, conditions, getBrandProducts, products.length]);

  useEffect(() => {
    const container = containerRef.current || tableRef.current;
    if (container != null) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (container != null) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  return <div className="flex flex-col gap-4 w-full">
    <div className="flex justify-between items-center">
      <div className={classNames(ClassNames.Title, "text-lg")}>Explore products</div>
      <ViewControl loading={loading} view={view} setView={setView} />
    </div>
    {
      view === "table" &&
      <div ref={tableRef} className="w-full h-[75vh] overflow-auto">
        <Table className="w-full" columns={["Name", "Price", "Description", "Tags", "Colors"]} currentPage={0} rows={rows} totalPages={1}
          onClickRow={index => onClick(products[index])}
          customElement={{
            3: (cell) => (
              <div className="px-2 flex gap-1">
                {isArray(cell.value) ? cell.value.map(label => (
                  <div key={label} className="bg-teal-500/50 px-2 py-[2px] w-fit rounded-3xl text-[10px]">{label}</div>
                )) : null}
              </div>
            ),
            4: (cell) => (
              <div className="px-2 flex gap-1 flex-wrap overflow-y-auto">
                {isArray(cell.value) ? cell.value.map(label => (
                  <div key={label} className="bg-teal-500/50 px-2 py-[2px] rounded-3xl text-[10px]">{label}</div>
                )) : null}
              </div>
            )
          }} />
      </div>
    }
    {
      view !== "table" &&
      <div ref={containerRef} className="flex flex-wrap w-full items-center gap-4 space-y-2 content-start h-[75vh] overflow-auto" >
          {products.map((product) => (
            <ProductCard key={product.Id} className={classNames({
              "w-[150px] h-[250px]": view === "image-sm",
              "w-[22vw] h-[50vw]": view === "image-md",
              "w-[45vw] h-[50vw]": view === "image-lg",
            })} product={product} onClick={() => onClick(product)} />
          ))}
      </div>
    }
  </div>
}

export const BrandPage: FC = () => {
  const [productToShow, setProductToShow] = useState<Product>();
  const brand: Brand = useLocation().state?.brand;
  const filterProps = useFilter();
 
  const handleQuery = useCallback(() => {
    filterProps.setConditions(getFilterConditions(filterProps));
  }, [filterProps]);

  if (brand == null) {
    return <Navigate to={InternalRoutes.Dashboard.path} />
  }

  return <InternalPage routes={[InternalRoutes.Dashboard, {
    ...InternalRoutes.Brands.Brands,
    name: toTitleCase(brand.Name),
  }]}>
    <div className="flex flex-col w-full h-full gap-4">
      <div className="flex justify-between w-full">
        <div className={classNames(ClassNames.Title, "text-2xl")}>Explore {toTitleCase(brand.Name)}</div>
        <Filter ignore={["brandIds"]} {...filterProps} onClick={handleQuery} />
      </div>
      <div className="flex flex-col w-full h-full gap-20">
        {
          brand != null &&
          <>
            <BrandAnalyticsDetails brand={brand} conditions={filterProps.conditions} />
            <BrandProductDetails brand={brand} conditions={filterProps.conditions} onClick={(product) => setProductToShow(product)} />
          </>
        }
        {
          productToShow != null &&
          <ProductDetails brand={brand} product={productToShow} onCancel={() => setProductToShow(undefined)} />
        }
      </div>
    </div>
  </InternalPage>
}