import { FC, useMemo } from "react";
import { ClassNames } from "../classes";
import classNames from "classnames";
import { Tooltip } from "../tooltip";
import { BG_COLORS } from "./common";
import { ExportButton, useExportButton } from "../export-button";

type IVerticalDistributionChartProps = {
    data: { name: string, value: number }[];
    allowExport?: boolean;
}

export const VerticalDistributionChart: FC<IVerticalDistributionChartProps> = ({ data, allowExport = false }) => {
    const { ref, downloading, exportOptions } = useExportButton();

    const maxValue = useMemo(() => {
        return Math.max(...data.map(datum => datum.value));
    }, [data]);

    return <div className={classNames("w-full h-full relative", ClassNames.ExportButton)}>
        { allowExport && <ExportButton downloading={downloading} exportOptions={exportOptions} /> }
        <div className="flex flex-col" ref={ref}>
            {
                data.map((datum, index) => (
                    <div key={datum.name} className="flex items-center bg-black/5 dark:bg-white/5">
                        <div className={classNames(ClassNames.Text, "py-1 px-4 w-1/3 text-sm")}>{datum.name}</div>
                        <div className="h-full w-2/3">
                            <Tooltip tooltip={datum.value}>
                                <div className="w-full py-3 px-4 bg-black/5 dark:bg-white/5 flex justify-center items-center hover:bg-black/10">
                                    <div className={classNames("rounded-full h-4 w-4", BG_COLORS[index % BG_COLORS.length])} style={{
                                        transform: `scale(${datum.value/maxValue})`,
                                    }} />
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                ))
            }
        </div>
    </div>
}