import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { sum } from 'lodash';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { Cell, Pie, PieChart, Tooltip } from 'recharts';
import { twMerge } from 'tailwind-merge';
import { hexToHSL } from '../../utils/functions';
import { ClassNames } from '../classes';
import { ExportButton, useExportButton } from '../export-button';
import { Icons } from '../icons';
import { BG_COLORS, FILL_COLORS, getColorFromText } from './common';


const COLLAPSED_LENGTH = 3;

type IPaddedPieChartProps = {
  height: number;
  width: number;
  data: { name: string; value: number }[];
  showDetails?: boolean;
  useCustomColors?: boolean;
  allowExport?: boolean;
};

const Portal: FC<{ children: React.ReactNode }> = ({ children }) => {
  return ReactDOM.createPortal(children, document.querySelector("#app-container")!);
};

export const PaddedPieChart: FC<IPaddedPieChartProps> = ({ height, width, data: originalData, showDetails, useCustomColors = false, allowExport = false }) => {
  const [hoveringChartCell, setHoveringChartCell] = useState<string>();
  const [hoveringInfo, setHoveringInfo] = useState<string>();
  const [active, setActive] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const { ref: chartRef, downloading: chartDownloading, exportOptions: chartExportOptions } = useExportButton();
  const { ref: bigChartRef, downloading: bigChartDownloading, exportOptions: bigChartExportOptions } = useExportButton();
  
  const handleOpen = useCallback(() => {
    setActive(true);
  }, []);

  const handleClose = useCallback(() => {
    setActive(false);
  }, []);

  useEffect(() => {
    if (active) {
      (document.querySelector("#app-container") as HTMLElement).style.overflow = 'hidden';
    } else {
      (document.querySelector("#app-container") as HTMLElement).style.overflow = 'auto';
    }
    return () => {
      (document.querySelector("#app-container") as HTMLElement).style.overflow = 'auto';
    };
  }, [active]);

  const entireHeight = window.innerHeight * 0.75;
  const entireWidth = window.innerWidth * 0.75;

  const data = useMemo(() => {
    if (!useCustomColors) {
      return originalData.map((datum, index) => ({
        ...datum,
        color: FILL_COLORS[index % FILL_COLORS.length],
        bgColor: BG_COLORS[index % BG_COLORS.length],
      }));
    }
   
    const dataWithHSL = originalData.map(datum => {
      const color = getColorFromText(datum.name);
      const hsl = hexToHSL(color);
      return {
        ...datum,
        color,
        bgColor: color,
        h: hsl.h,
        l: hsl.l,
      };
    });

    dataWithHSL.sort((a, b) => {
      const diffLightness = a.l - b.l;
      if (Math.abs(diffLightness) < 1e-2) {
        return a.h - b.h;
      }
      if (a.l > b.l) {
        return 1;
      }
      return -1;
    });

    return dataWithHSL;
  }, [originalData, useCustomColors]);

  const totalSum = useMemo(() => sum(data.map(datum => datum.value)), [data]);

  const percentageDistribution = useMemo(() => {
    return data
      .map(datum => ({
        ...datum,
        percentage: (datum.value / totalSum) * 100
      }))
      .sort((a, b) => b.percentage - a.percentage);
  }, [data, totalSum]);

  return (
    <div className="w-full h-full group/pie-chart">
      <AnimatePresence>
        {active && (
          <Portal>
            <motion.div
              className="fixed inset-0 bg-white/10 dark:bg-black/10 z-50 backdrop-blur-md"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={handleClose}
            />
            <motion.div
              className={classNames("fixed inset-0 flex justify-center items-center z-50", ClassNames.ExportButton)}
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              transition={{ duration: 0.4, ease: 'easeInOut' }}
              onClick={handleClose}
            >
              <ExportButton className="top-8 right-8" downloading={bigChartDownloading} exportOptions={bigChartExportOptions} />
              <div className="flex justify-center items-center" ref={bigChartRef}>
                <div className="flex justify-center items-center relative">
                  <div className="absolute inset-0 flex justify-center items-center pointer-events-none">
                    <div className={classNames(ClassNames.Text, "text-4xl")}>{totalSum}</div>
                  </div>
                  <PieChart
                    className="relative"
                    height={entireHeight}
                    width={entireWidth}
                    onClick={handleClose}
                  > 
                    <Tooltip content={(data) => (<div className={ClassNames.ChartTooltip}>
                      {data.payload?.at(0)?.name} ({data.payload?.at(0)?.value})
                    </div>)} />
                    <Pie className="my-[50px]" data={data} innerRadius={entireHeight/3} outerRadius={entireWidth/4.2} fill="#8884d8" paddingAngle={1} dataKey="value">
                      {data.map((datum) => (
                        <Cell
                          className={twMerge(classNames('stroke-none', {
                            "stroke-black/50 dark:stroke-white/50": hoveringInfo === datum.name,
                          }, {
                            [datum.color]: !useCustomColors,
                          }))}
                          onMouseEnter={() => setHoveringChartCell(datum.name)}
                          onMouseLeave={() => setHoveringChartCell(undefined)}
                          key={`cell-${datum.name}`}
                          style={{
                            fill: useCustomColors ? datum.color : undefined,
                          }}
                        />
                      ))}
                    </Pie>
                  </PieChart>
                </div>
                <div className="ml-4 flex flex-col items-start text-white space-y-1 w-[250px] h-[80vh] overflow-y-auto">
                  {data.map((datum) => (
                    <div key={`legend-item-${datum.name}`} className={classNames("flex items-center space-x-2 transition-all px-4 w-full py-1 hover:bg-black/5 dark:hover:bg-white/5 rounded-lg", {
                      "bg-black/5 dark:bg-white/5 rounded-xl": datum.name === hoveringChartCell, 
                    })}
                      onMouseEnter={() => setHoveringInfo(datum.name)}
                      onMouseLeave={() => setHoveringInfo(undefined)}>
                      <div className={classNames("w-8 h-4 rounded-2xl", {
                        [datum.bgColor]: !useCustomColors,
                      })} style={{
                        backgroundColor: useCustomColors ? datum.bgColor : undefined,
                      }} />
                      <span className={classNames(ClassNames.Text, "text-base w-full")}>{datum.name}</span>
                      <span className={classNames(ClassNames.Text, "text-base")}>({datum.value})</span>
                    </div>
                  ))}
                </div>
              </div>
            </motion.div>
          </Portal>
        )}
      </AnimatePresence>
      <div className={classNames("flex relative", ClassNames.ExportButton)}>
        { allowExport && <ExportButton downloading={chartDownloading} exportOptions={chartExportOptions} />}
        <div className="flex flex-col w-full items-center" ref={chartRef}>
          <div className="flex justify-center items-center relative">
            <div className="absolute inset-0 flex justify-center items-center pointer-events-none opacity-50 group-hover/pie-chart:opacity-100 transition-all">
              <div className={classNames(ClassNames.Text, "text-sm")}>{totalSum}</div>
            </div>
            <PieChart height={height} width={width} onClick={handleOpen} className="transition-all !cursor-pointer hover:scale-105">
              <Pie data={data} innerRadius={height/3} outerRadius={width/3.2} fill="#8884d8" paddingAngle={1} dataKey="value">
                {data.map((datum) => (
                  <Cell className={twMerge(classNames('stroke-none', {
                    "stroke-black/50 dark:stroke-white/50": hoveringInfo === datum.name,
                  }, {
                    [datum.color]: !useCustomColors,
                  }))} key={`cell-${datum.name}`}
                    onMouseEnter={() => setHoveringChartCell(datum.name)}
                    onMouseLeave={() => setHoveringChartCell(undefined)} style={{
                      fill: useCustomColors ? datum.color : undefined,
                    }} />
              ))}
              </Pie>
              <Tooltip content={(data) => (<div className={classNames(ClassNames.IgnoreHTMLToImage, ClassNames.ChartTooltip)}>
                {data.payload?.at(0)?.name} ({data.payload?.at(0)?.value})
              </div>)} />
            </PieChart>
          </div>
          {
            showDetails && 
            <div className="flex flex-col w-full gap-1">
              {
                showDetails && percentageDistribution.slice(0, showMore ? percentageDistribution.length : COLLAPSED_LENGTH).map((datum) => (
                  <div className={classNames("flex justify-between items-center w-full transition-all hover:bg-black/5 dark:hover:bg-white/5 px-2 py-1 rounded-lg", {
                    "bg-black/5 dark:bg-white/5 rounded-xl": datum.name === hoveringChartCell, 
                  })} key={`legend-item-${datum.name}`}
                    onMouseEnter={() => setHoveringInfo(datum.name)}
                    onMouseLeave={() => setHoveringInfo(undefined)}>
                    <div className="flex items-center gap-2">
                      <div className={classNames("w-4 h-4 rounded-lg", {
                        [datum.bgColor]: !useCustomColors,
                      })} style={{
                        backgroundColor: useCustomColors ? datum.bgColor : undefined,
                      }} />
                      <div className={ClassNames.Text}>{datum.name}</div>
                    </div>
                    <div className={ClassNames.Text}>{datum.percentage.toFixed(1)}%</div>
                  </div>
                ))
              }
              {
                percentageDistribution.length > COLLAPSED_LENGTH && 
                <div className={classNames(ClassNames.Button, "self-end", ClassNames.IgnoreHTMLToImage)} onClick={() => setShowMore(status => !status)}>
                  <span className={classNames("transition-all", {
                    "rotate-180": showMore,
                  })}>{Icons.DownChevron}</span>
                  <div className={classNames(ClassNames.Text, "text-sm")}>Show {showMore ? "less" : "more"}</div>
                </div>
              }
            </div>
          }
        </div>
      </div>
    </div>
  );
};
