import classNames from "classnames";
import { map } from "lodash";
import { Route, Routes } from "react-router-dom";
import './app.css';
import { KeycloakProvider } from "./components/keycloak";
import { Notifications } from "./components/notifications";
import { getRoutes } from "./config/routes";
import { useAppSelector } from "./store/hooks";

export const App = () => {
  const darkModeEnabled = useAppSelector(state => state.global.theme === "dark");
  return (
    <div className={classNames("h-[100vh] w-[100vw]", {
      "dark": darkModeEnabled,
    })} id="app-container">
      <KeycloakProvider>
        <Notifications />
        <Routes>
          {map(getRoutes(), route => (
            <Route key={route.path} path={route.path} element={route.component} />
          ))}
        </Routes>
      </KeycloakProvider>
    </div>
  );
}