import { FC, useCallback, useMemo, useRef, useState } from "react";
import { Dropdown, getDropdownItem, IDropdownItem } from "./dropdown";
import { twMerge } from "tailwind-merge";
import classNames from "classnames";
import { ClassNames } from "./classes";
import { toJpeg, toPng } from "html-to-image";
import { Icons } from "./icons";

export const useExportButton = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [downloading, setDownloading] = useState(false);

  const handlExport = useCallback((scale = 1, format = 'png') => {
    if (ref.current == null) {
      return;
    }
  
    (format === "jpeg" ? toJpeg : toPng)(ref.current, {
      pixelRatio: scale*3,
      filter(domNode) {
        return !domNode.classList?.contains("nk-chart-ignore");
      },
    })
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.download = `nanoknot-chart.${format}`;
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.error('Could not capture the image', err);
      }).finally(() => {
          setDownloading(false);
      });
  }, []);

  const exportOptions: IDropdownItem[] = useMemo(() => {
    return [
      { ...getDropdownItem("Export png (1x)"), onClick: () => handlExport(1, "png") },
      { ...getDropdownItem("Export png (2x)"), onClick: () => handlExport(2, "png") },
      { ...getDropdownItem("Export png (4x)"), onClick: () => handlExport(4, "png") },
      { ...getDropdownItem("Export jpeg (1x)"), onClick: () => handlExport(1, "jpeg") },
      { ...getDropdownItem("Export jpeg (2x)"), onClick: () => handlExport(2, "jpeg") },
      { ...getDropdownItem("Export jpeg (4x)"), onClick: () => handlExport(4, "jpeg") },
    ];
  }, [handlExport]);

  return {
    ref,
    exportOptions,
    downloading,
  }
}

export const ExportButton: FC<{ className?: string } & Omit<ReturnType<typeof useExportButton>, "ref">> = ({ className, downloading, exportOptions }) => {
    return <div className={twMerge(classNames(ClassNames.Text, ClassNames.Button, "absolute right-2 top-2 opacity-0 pointer-events-none group-hover/export-button:opacity-100 group-hover/export-button:pointer-events-auto z-10", {
        "opacity-0 pointer-events-none": downloading,
      }, className))}>
        <Dropdown items={exportOptions} selectedId={undefined} onlyHover={true}>
            {Icons.Download}
        </Dropdown>
      </div>
}