export const ClassNames = {
    BackgroundGradient: "bg-gradient-to-r dark:from-black/90 dark:to-black/95",
    Text: "text-neutral-600 dark:text-neutral-300",
    Title: "text-neutral-600 dark:text-neutral-300 font-title",
    Button: "text-neutral-600 dark:text-neutral-300 cursor-pointer flex gap-1 hover:gap-2 transition-all items-center",
    TitledButton: "text-neutral-600 dark:text-neutral-300 cursor-pointer flex gap-1 hover:gap-2 transition-all items-center font-title",
    OutlinedButton: "text-neutral-600 dark:text-neutral-300 cursor-pointer flex gap-1 hover:gap-2 transition-all items-center border border-neutral-300/50 dark:border-neutral-300/10 px-2 py-1 rounded-xl",
    Card: "border-[1px] border-black/5 dark:border-none dark:bg-white/10 backdrop-blur-lg shadow-lg rounded-2xl flex",
    ChartTooltip: "text-neutral-600 dark:text-neutral-300 text-sm bg-white/5 dark:bg-black/5 backdrop-blur-lg shadow shadow-black/10 dark:shadow-white/10 p-4 rounded-lg flex flex-col gap-1 !z-50",
    ActiveText: "text-teal-600 dark:text-teal-500",
    ExportButton: "group/export-button",
    IgnoreHTMLToImage: "nk-chart-ignore",
}