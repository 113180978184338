import { camelCase } from "lodash";

export const FILL_COLORS = [
    "fill-cyan-500 dark:fill-cyan-800","fill-amber-400 dark:fill-amber-700", "fill-amber-500 dark:fill-amber-800", "fill-fuchsia-400 dark:fill-fuchsia-700", "fill-fuchsia-500 dark:fill-fuchsia-800",
    "fill-violet-400 dark:fill-violet-700", "fill-violet-500 dark:fill-violet-800", "fill-emerald-400 dark:fill-emerald-700", "fill-emerald-500 dark:fill-emerald-800", "fill-gray-400 dark:fill-gray-700", "fill-gray-500 dark:fill-gray-800", "fill-stone-400 dark:fill-stone-700", "fill-stone-500 dark:fill-stone-800",
    "fill-neutral-400 dark:fill-neutral-700", "fill-neutral-500 dark:fill-neutral-800", "fill-sky-400 dark:fill-sky-700", "fill-sky-500 dark:fill-sky-800",
    "fill-rose-400 dark:fill-rose-700", "fill-rose-500 dark:fill-rose-800", "fill-teal-400 dark:fill-teal-700", "fill-teal-500 dark:fill-teal-800", 
    "fill-orange-400 dark:fill-orange-700", "fill-orange-500 dark:fill-orange-800", "fill-lime-400 dark:fill-lime-700", "fill-lime-500 dark:fill-lime-800",
    "fill-purple-400 dark:fill-purple-700", "fill-purple-500 dark:fill-purple-800", "fill-yellow-400 dark:fill-yellow-700", "fill-yellow-500 dark:fill-yellow-800",
    "fill-green-400 dark:fill-green-700", "fill-green-500 dark:fill-green-800", "fill-indigo-400 dark:fill-indigo-700", "fill-indigo-500 dark:fill-indigo-800",
    "fill-pink-400 dark:fill-pink-700", "fill-pink-500 dark:fill-pink-800", "fill-red-400 dark:fill-red-700", "fill-red-500 dark:fill-red-800", "fill-blue-400 dark:fill-blue-700", 
    "fill-blue-500 dark:fill-blue-800", "fill-cyan-400 dark:fill-cyan-700",
];

export const BG_COLORS = [
    "bg-cyan-500 dark:bg-cyan-800","bg-amber-400 dark:bg-amber-700", "bg-amber-500 dark:bg-amber-800", "bg-fuchsia-400 dark:bg-fuchsia-700", "bg-fuchsia-500 dark:bg-fuchsia-800",
    "bg-violet-400 dark:bg-violet-700", "bg-violet-500 dark:bg-violet-800", "bg-emerald-400 dark:bg-emerald-700", "bg-emerald-500 dark:bg-emerald-800", "bg-gray-400 dark:bg-gray-700", "bg-gray-500 dark:bg-gray-800", "bg-stone-400 dark:bg-stone-700", "bg-stone-500 dark:bg-stone-800",
    "bg-neutral-400 dark:bg-neutral-700", "bg-neutral-500 dark:bg-neutral-800", "bg-sky-400 dark:bg-sky-700", "bg-sky-500 dark:bg-sky-800",
    "bg-rose-400 dark:bg-rose-700", "bg-rose-500 dark:bg-rose-800", "bg-teal-400 dark:bg-teal-700", "bg-teal-500 dark:bg-teal-800", 
    "bg-orange-400 dark:bg-orange-700", "bg-orange-500 dark:bg-orange-800", "bg-lime-400 dark:bg-lime-700", "bg-lime-500 dark:bg-lime-800",
    "bg-purple-400 dark:bg-purple-700", "bg-purple-500 dark:bg-purple-800", "bg-yellow-400 dark:bg-yellow-700", "bg-yellow-500 dark:bg-yellow-800",
    "bg-green-400 dark:bg-green-700", "bg-green-500 dark:bg-green-800", "bg-indigo-400 dark:bg-indigo-700", "bg-indigo-500 dark:bg-indigo-800",
    "bg-pink-400 dark:bg-pink-700", "bg-pink-500 dark:bg-pink-800", "bg-red-400 dark:bg-red-700", "bg-red-500 dark:bg-red-800", "bg-blue-400 dark:bg-blue-700", 
    "bg-blue-500 dark:bg-blue-800", "bg-cyan-400 dark:bg-cyan-700",
];

export function getColorFromText(name: string) {
    const colorMap: Record<string, string> = {
        black: "#000000",
        white: "#FFFFFF",
        red: "#FF0000",
        blue: "#0000FF",
        green: "#008000",
        yellow: "#FFFF00",
        pink: "#FFC0CB",
        orange: "#FFA500",
        purple: "#800080",
        brown: "#A52A2A",
        gray: "#808080",
        beige: "#F5F5DC",
        navy: "#000080",
        teal: "#008080",
        lavender: "#E6E6FA",
        coral: "#FF7F50",
        burgundy: "#800020",
        gold: "#FFD700",
        silver: "#C0C0C0",
        turquoise: "#40E0D0",
        cream: "#FFFDD0",
        charcoal: "#36454F",
        olive: "#808000",
        mint: "#98FF98",
        peach: "#FFE5B4",
        aqua: "#00FFFF",
        magenta: "#FF00FF",
        plum: "#DDA0DD",
        mustard: "#FFDB58",
        indigo: "#4B0082",
        ivory: "#FFFFF0",
        fuchsia: "#FF00FF",
        emerald: "#50C878",
        amber: "#FFBF00",
        crimson: "#DC143C",
        violet: "#EE82EE",
        cobaltBlue: "#0047AB",
    };
    
    return colorMap[camelCase(name)] ?? "#000000";
}
