import { AnimatePresence, motion } from "framer-motion";
import { FC, ReactNode } from "react";
import { twMerge } from "tailwind-merge";
import { IInternalRoute } from "../config/routes";
import { TopBar } from "./top-bar";
import { useAppSelector } from "../store/hooks";

type IPageProps = {
    wrapperClassName?: string;
    className?: string;
    children: ReactNode;
}

export const Page: FC<IPageProps> = (props) => {
    return <div className={twMerge("flex grow px-8 py-6 flex-col h-full w-full bg-white dark:bg-black/90", props.wrapperClassName)}>
        <AnimatePresence>
            <motion.div className={twMerge("flex flex-row grow flex-wrap gap-2 w-full h-full overflow-y-auto", props.className)}
                initial={{ opacity: 0 }}
                animate={{ opacity: 100, transition: { duration: 0.5 } }}
                exit={{ opacity: 0 }}>
                    {props.children}
            </motion.div>
        </AnimatePresence>
    </div>
}

type IInternalPageProps = IPageProps & {
    children: ReactNode;
    routes?: IInternalRoute[];
}

export const InternalPage: FC<IInternalPageProps> = (props) => {
    const isAuthenticated = useAppSelector(state => state.auth.status === "success");

    return (
        <div className="flex grow h-full w-full">
            <Page wrapperClassName="p-0" {...props}>
                {/* <SideBar /> */}
                <div className="flex flex-col grow pt-4">
                    { isAuthenticated && <TopBar routes={props.routes} /> }
                    <div className="flex grow flex-wrap gap-2 py-4 content-start relative px-8">
                        {props.children}
                    </div>
                </div>
            </Page>
        </div>
    )
}